import "../styles/template.css";
import "../styles/dashboard.css";
import TopNav from "../components/TopNav";
import SideNavbar from "../components/SideNavbar";
import { BsCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import img from "../images/loginBackground.61eb979b.jpg"
import pause from "../images/pause.959c0e50.svg"
import play from "../images/play.4ade234c.svg"
import { useState,useEffect } from "react";
import { homePage,checkIn } from "../api";
import { ScaleLoader } from "react-spinners";
import Employees from "../components/Employees";
import Birthday from "../components/Birthday";
import BirthdayMessage from "../components/BirthdayMessage";
import { checkBirthday,updateToken } from "../api";
import { FaBirthdayCake } from "react-icons/fa";
import Checkout from "../components/Checkout";
import useGeoLocation from "../components/useGeoLocation";
import ErrorModal from "../components/ErrorModal";
function Home() {
    const { t,i18n } = useTranslation();
    const location = useGeoLocation();

const[checkin,setCheckin]=useState(false)
const[loading,setLoading]=useState(true)
const[homeObj,setHomeObj]=useState()
const[departments,setDepartments]=useState([])
const[hours,setHours]=useState(0)
const[seconds,setSeconds]=useState(0)
const[minutes,setMinutes]=useState(0)
const[pauseTimer,setPauseTimer]=useState(false)
const[show,setShow]=useState(false)
const[timerStopped,setTimerStopped]=useState(true)
const[timerStarted,setTimerStarted]=useState(false)
const[hoursPercentage,setHoursPercentage]=useState(0)
const[vacationsPercentage,setVacationsPercentage]=useState(0)
const[leavingPercentage,setLeavingPercentage]=useState(0)
let timer;
const[sessionState,setSessionState]=useState(false)
const[start,setStart]=useState(false)
const[birthdayModal,setBirthdayModal]=useState(false)
const[birthdayMsg,setBirthdayMsg]=useState(false)
const[birthdays,setBirthdays]=useState([])
const[birthdayEmp,setBirthdayEmp]=useState({})
const[checkoutModal,setCheckoutModal]=useState(false)
const[errorModal,setErrorModal]=useState(false)
const[errMsg,setErrMsg]=useState()

useEffect(()=>{
 
const values={
    api_token: localStorage.getItem("token")
}

checkBirthday(values).then((response) => {
    setBirthdays(response.data.data)
})

const values2={
    api_token: localStorage.getItem("token"),
    pushToken: localStorage.getItem("pushToken")
}

updateToken(values2).then((response) => {

}).catch(error => {
    if (error.response.status === 401) {
        localStorage.setItem("token", null);
        window.location.pathname = "/";
    }
});
    homePage(values).then((response) => {
        setDepartments( response.data.departments)
        setHomeObj( response.data)
        setLoading(false)

        if (response.data.hoursTotal > 0) {
            setHoursPercentage((response.data.hoursValue / parseFloat(response.data.hoursTotal)) * 100);
        }
        if (response.data.vacationsTotal > 0) {
            setVacationsPercentage ((response.data.vacationsValue / parseFloat(response.data.vacationsTotal)) * 100)
        }
        if (response.data.leavesTotal) {
            setLeavingPercentage ((response.data.leavesValue / response.data.leavesTotal) * 100);
        }


        localStorage.setItem("company_firebase", response.data.company_firebase);
        localStorage.setItem("user_firebase", response.data.user_firebase);
        localStorage.setItem("hasTeam", response.data.hasTeam);

     if (response.data.show === "yes") {
         setShow(true)
            setPauseTimer(response.data.break)
            setHours(response.data.hours)
            setMinutes(response.data.minutes)
            setSeconds(response.data.seconds)
            setStart(!start)


              if (response.data.sessionState === "true") {
               setSessionState(true)
               setStart(!start)

          
       
        }
      //  handleTimerStart()

     
  }
       }).catch(function(error){
 
        if (error.response.status === 401) {
            localStorage.setItem("token", null);
            window.location.pathname = "/";
        }
         })
    

},[])


function startCount () {
    let values = {
        api_token: localStorage.getItem("token"),
        longitude: localStorage.getItem("longitude"),
        latitude: localStorage.getItem("latitude"),
    };
    if(localStorage.getItem("longitude") !== null && localStorage.getItem("latitude") !== null) {
        checkIn(values).then((response) => {
            if (response.data.message === 'success') {
            
             window.location.reload();
            }
        }).catch(function (error) {
          
        });
    }else{
        
            setErrorModal(true)
            setErrMsg("dashboard.locErr")
        
    }
}

useEffect(()=>{

   
    if (timerStopped) {
        setTimerStarted(true)
        setTimerStopped( false)
        let sec=seconds
        let min= minutes
         timer =   setInterval(() => {
          
          if(true){
  
                if (sec > 60) {
                    setMinutes(prevCount => prevCount + 1)

                    setSeconds(prevCount => prevCount * 0)
                    sec=0
                    min=min+1
                }
                if (min > 60) {
                    setHours(prevCount => prevCount + 1)
                    setMinutes(prevCount => prevCount * 0)
                    setSeconds(prevCount => prevCount * 0)
                    sec=0
                    min=0
               
            } 
            setSeconds(prevCount => prevCount+1)
            sec=sec+1
        }
        }, 1000);
     
    
 
}},[start])


   
/*
function handleTimerStop() {
    setTimerStarted(false)
    setTimerStopped(true)
    clearInterval(timer);
}*/


function  takeBreak()  {
    let values = {
        api_token: localStorage.getItem("token"),
        longitude: localStorage.getItem("longitude"),
        latitude: localStorage.getItem("latitude"),
        type: 'break'
    };
    if(localStorage.getItem("longitude") !== null && localStorage.getItem("latitude") !== null) {
        checkIn(values).then((response) => {
            if (response.data.message === 'success') {
                setStart(!start)
            window.location.reload();
        
            }
        })
    }else{
        setErrorModal(true)
        setErrMsg("dashboard.locErr")
    }
};
  
    return ( 
        <div className="template" >
            {birthdayModal? <Birthday setBirthdayMsg={setBirthdayMsg} setBirthdayEmp={setBirthdayEmp} birthdays={birthdays} setModal={setBirthdayModal}/>:null}
            {birthdayMsg? <BirthdayMessage data={birthdayEmp}  setModal={setBirthdayMsg} />:null}
{checkoutModal? <Checkout setModal={setCheckoutModal}/>:null}
{errorModal? <ErrorModal setModal={setErrorModal} errMsg={errMsg}/>:null}
<div className="container-fluid" style={{height:"100%"}}>
<div className="row" style={{height:"100%"}}>
<div className="col-lg-3 col-md-4" style={{padding:"0"}}>
<SideNavbar/>
</div>

<div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
<TopNav/>
{loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:
<div className="dashboardBody">
<div className="percentage">
<div className="circle">
<div>
<p className="percent">{hoursPercentage}%</p>
<p>{t("dashboard.hours")}</p>
</div>
</div>
<div className="circle">
<div>
<p className="percent">{vacationsPercentage}%</p>
<p>{t("dashboard.vacations")}</p>
</div>
</div>
<div className="circle">
<div>
<p className="percent">{leavingPercentage}%</p>
<p>{t("dashboard.leaves")}</p>
</div>
</div>
</div>
<div className="log">
{show?
<div>
    <div className="timer" style={homeObj.break=="true"?{backgroundColor: "#7aa1f0",color:"white"}:null}>
     
    { `${(hours < 10 ? `0${hours}` : hours)}` + ":" + `${(minutes < 10 ? `0${minutes}` : minutes)}` + ":"  + `${(seconds < 10 ? `0${seconds}` : seconds)}`}

    </div>
  {!sessionState?  <p className="pending">{t("dashboard.pending")}</p>:null}
<div className="checkoutBtns">
<button onClick={takeBreak}  className={i18n.language=="ar"?"pauseAr pause":"pause"}>

    <img src={pause}/>
</button>

<button className="checkout" onClick={()=>setCheckoutModal(true)}>{t("dashboard.checkout")}</button>

<button onClick={takeBreak}  className={i18n.language=="ar"?"playAr play":"play"}>
<img src={play}/>


</button>


    </div>
    </div>
:
<button className="checkin" onClick={startCount}>{t("dashboard.checkin")}</button>

}
{departments.length>0 ?
<div className="activeDepSec">
    {birthdays.length>0?
    <div className="bdButton">
    <FaBirthdayCake size={35} onClick={()=>setBirthdayModal(true)}/></div>:null}
{departments.map((data,index)=>
    < >
<p key={index}><BsCircleFill  style={i18n.language=="ar"?{color:"var(--themeColor)",marginLeft:"1em"}:{color:"var(--themeColor)",marginRight:"1em"}}/>{data.name}</p>
<Employees employees= {data.employees}/>
        <span style={{margin:"0 2em"}}></span>
       
        <hr/></>)}
      
      
</div>:null}

</div>

</div>}

<div className="footer">
<p>{t("login.footer")}</p>
</div>
</div>
</div>
</div>

</div>)
}

export default Home
