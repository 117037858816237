
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';

import { FaCheckCircle } from 'react-icons/fa';

function Notifications(props) {
   
    const { t, i18n } = useTranslation();
 
  
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} style={{background:"transparent"}} className="modalOverlay"></div>
        <div  className="modal-content" style={i18n.language=="ar"?{right:"unset"}:null}>
    <div className='notifyDiv'>  
 
    {props.data && props.data.map((data,index)=>

      <div className='notif'  onClick={()=>
        {  props.setText({
    title:data.title,
    msg:data.msg
        })
          props.setShowData(true)}}>
<div>
    <p> {data.state=="seen"?<FaCheckCircle size={15} style={{color:"var(--themeColor)"}}/>:<BsCircleFill size={12} style={{color:"var(--themeColor)"}}/>}<span style={i18n.language=="ar"?{marginRight:"1em"}:{marginLeft:"1em"}}>{data.title}</span></p>
    <p className='notifyDate'>{data.time}</p>
 
    </div>
    <hr/>
    </div>)}
    </div>  
        </div>
      </div>
  
    );
  }
  
  export default Notifications;
  
  