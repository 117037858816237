import ReactDOM from 'react-dom/client';
import  './i18next';
import React, {Suspense}  from 'react';
import "bootstrap/dist/css/bootstrap.css"

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<div>Loading...</div>}>
  <App />
  </Suspense>)


