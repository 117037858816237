import "../styles/checkin.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import UsrSwiper from "../components/UsrSwiper";
import { useState,useEffect,useRef } from "react";
import { editTask,employees,tasks} from "../api";
import { ScaleLoader } from "react-spinners";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router";
import { useParams } from 'react-router-dom';
 import { addTask } from "../api";
function AddTask() {
    const { t ,i18n} = useTranslation();
    const { id } = useParams();
    let history=useNavigate()

 
    const[loading,setLoading]=useState(false)
    const[employeesArr,setEmployeesArr]=useState([])
    const[selectedEmployees,setSelectedEmployees]=useState([])

    const[employeeErr,setEmployeeErr]=useState(false)
    const[colorErr,setColorErr]=useState(false)

    const[color,setColor] = useState("")
 
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const mindate = yyyy + '-' + mm + '-' + dd;

    const Schema= yup.object().shape({
        title: yup.string().required(("validation.title_required")),
        project: yup.string().required(("validation.project_required")),
        deadline: yup.string().required(("validation.dateLine_required")),
        describe: yup.string().required(("validation.description_required")),
        })
    
 

        const formik = useFormik({
            initialValues:{
              title:"",
              project:"",
              deadline:"",
              describe:"",
            },
            validationSchema: Schema,
            onSubmit,
            
            });
        
            const titleRef=useRef();
            const projectRef=useRef();
            const deadlineRef=useRef();
                const describeRef=useRef();




                useEffect(()=>{
  
                    const values = {
                           api_token: localStorage.getItem("token"),
                           type:"team"
                       }
                
                 
                  employees(values).then((response) => {
                        setEmployeesArr(response.data.data)
                        setLoading(false)
                
                }).catch(function (error) {
                    if (error.response.status === 401){
                       window.location.pathname="/"
                    }
                  
                })
                

                
                },[])



                async function onSubmit(event){
                    const values = {
                        api_token: localStorage.getItem("token"),
                        id: id,
                        color:color,
                        title: titleRef.current.value,
                        project_name: projectRef.current.value,
                        deadline: deadlineRef.current.value,
                        description: describeRef.current.value,
                        ids: JSON.stringify(selectedEmployees),
  
                    }
if(color==""){
    setColorErr(true)
}
else{
                    addTask(values).then((response) => {

                        history("/my-tasks")

                    })

                
                }   
            }
    
    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>
        {  loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:
        <div className="addMeeting " >
       <p>{t("meetings.edit")}</p>
    
      <form className="meetingForm" onSubmit={formik.handleSubmit}>
<div className="meetingFlex">
    <label>{t("meetings.title")}</label>
    <div style={{width:"80%"}}>
    <input value={formik.values.title}  name="title"  ref={titleRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.title && formik.touched.title?"invalid":null}/>
       <p className="errMsg">{formik.errors.title && formik.touched.title?t(`${formik.errors.title}`):null}</p>
       </div>
</div>
<div className="meetingFlex">
    <label>{t("tasks.project")}</label>
    <div style={{width:"80%"}}>
    <input  min={mindate} value={formik.values.project}  name="project"  ref={projectRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.project && formik.touched.project?"invalid":null}/>
       <p className="errMsg">{formik.errors.project && formik.touched.project?t(`${formik.errors.project}`):null}</p>
</div>
</div>
<div className="meetingFlex">
    <label>{t("tasks.deadline")}</label>
    <div style={{width:"80%"}}>
    <input type="date" min={mindate} value={formik.values.deadline}  name="deadline"  ref={deadlineRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.deadline && formik.touched.deadline?"invalid":null}/>
       <p className="errMsg">{formik.errors.deadline && formik.touched.deadline?t(`${formik.errors.deadline}`):null}</p>
</div>
</div>
 
<div className="meetingFlex">
    <label>{t("tasks.color")}</label>
    <div style={{width:"80%"}} className="colorCode">
     
 <div onClick={()=>setColor("#B724D1")} className={color=="#B724D1"?"activeColor":null} style={{backgroundColor:"rgb(183, 36, 209)"}}></div>
 <span></span>
 <div  onClick={()=>setColor("#24D1D1")}  className={color=="#24D1D1"?"activeColor":null} style={{backgroundColor:"rgb(36, 209, 209)"}}></div>
 <span></span>

 <div onClick={()=>setColor("#103ED6")}  className={color=="#103ED6"?"activeColor":null}  style={{backgroundColor:"rgb(16, 62, 214)"}}></div>
 <span></span>

 <div onClick={()=>setColor("#FCEA1B")}  className={color=="#FCEA1B"?"activeColor":null} style={{backgroundColor:"rgb(252, 234, 27)"}}></div>
 <span></span>

 <div onClick={()=>setColor("#18D610")}  className={color=="#18D610"?"activeColor":null} style={{backgroundColor:"rgb(24, 214, 16)"}}></div>
</div>
</div>
{colorErr?
<p className="errMsg">{t("tasks.colorErr")}</p>:null}


<textarea placeholder={t("meetings.describe")} value={formik.values.describe}  name="describe"  ref={describeRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.describe && formik.touched.describe?"invalid":null}/>
       <p className="errMsg">{formik.errors.describe && formik.touched.describe?t(`${formik.errors.describe}`):null}</p>

      
        <div className="invite">
            <h1>{t("meetings.invite")}</h1>
<UsrSwiper setSelectedEmployees={setSelectedEmployees} employees={employeesArr} selectedEmployees={selectedEmployees}/>
{employeeErr?<p className="errMsg">{t("meetings.employeeErr")}</p>:null}

        </div>
        <button type="submit">{t("meetings.save")}</button>
        </form>
        </div>}
  

        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}


export default AddTask
