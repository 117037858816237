import firebase from 'firebase/compat/app';
import 'firebase/compat/database'; // If using Firebase database
import 'firebase/compat/storage';  // If using Firebase storage
import 'firebase/compat/messaging';  // If using Firebase messaging
import { getMessaging, getToken } from 'firebase/messaging'; 

    const config = {
        apiKey: "AIzaSyDaE2a23n7Vtv-Xs8ytt-jvbEwKGhecaS8",
        authDomain: "bdaiat-hr.firebaseapp.com",
        databaseURL: "https://bdaiat-hr.firebaseio.com",
        projectId: "bdaiat-hr",
        storageBucket: "bdaiat-hr.appspot.com",
        messagingSenderId: "1022590909449",
        appId: "1:1022590909449:web:9fe081ef9e4bcd50d1ccd8"
    };

    // Initialize Firebase
    //firebase.initializeApp(config)
    function isSupported (){
        if(
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window){
        
        return true
      }
      else{
        return false
      } 
      }
      
    export const app = firebase.initializeApp(config);
    export const messaging = getMessaging(app);

 
    export const askForPermissioToReceiveNotifications = async () => {
      if(isSupported()){
        console.log("supported")
        try {
       
           await Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              getToken(messaging, {
                vapidKey:
                "BF2pnfodiCigTknpiKr5UNzL6t8PxG08mRxPPRXBQLwDj3S2nhSkopwijcMIPE8wA4CJxuq8rHPQywaiL3stvXM",
              }).then((currentToken) => {

           
            if (currentToken) {
                localStorage.setItem('pushToken', currentToken);
            }
            return currentToken;

          })
          }})
        } catch (error) {
            console.error(error);
        }
    }}

export default firebase;