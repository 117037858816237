import "../styles/checkin.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import arrow from '../images/toggle.6fa3fa05.svg'
import UsrSwiper from "../components/UsrSwiper";
import { useState,useEffect,useRef } from "react";
import { meetings,employees,editMeeting } from "../api";
import { ScaleLoader } from "react-spinners";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router";
import { useParams } from 'react-router-dom';

function EditMeeting() {
    const { t ,i18n} = useTranslation();
    const { id } = useParams();
    let history=useNavigate()

    const[meeting,setMeeting] = useState({})
    const[loading,setLoading]=useState(false)
    const[employeesArr,setEmployeesArr]=useState([])
    const[selectedEmployees,setSelectedEmployees]=useState([])
    const[timeErr,setTimeErr]=useState(false)
    const[employeeErr,setEmployeeErr]=useState(false)

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const mindate = yyyy + '-' + mm + '-' + dd;
 
    const Schema= yup.object().shape({
        title: yup.string().required(("validation.title_required")),
        date: yup.string().required(("validation.date_required")),
        from: yup.string().required(("validation.time_required")),
        to: yup.string().required(("validation.time_required")),
        describe: yup.string().required(("validation.description_required")),
        })
    
 

        const formik = useFormik({
            initialValues:{
              title:"",
              date:"",
              from:"",
              to:"",
              describe:"",
            },
            validationSchema: Schema,
            onSubmit,
            
            });
        
            const titleRef=useRef();
            const dateRef=useRef();
            const fromRef=useRef();
            const toRef=useRef();
                const describeRef=useRef();




                useEffect(()=>{
  
                    const values = {
                           api_token: localStorage.getItem("token")
                       }
                
                 
                  employees(values).then((response) => {
                        setEmployeesArr(response.data.data)
                        setLoading(false)
                
                }).catch(function (error) {
                    if (error.response.status === 401){
                       window.location.pathname="/"
                    }
                  
                })
                
                       meetings(values).then((response) => {
                
                     
                        let dates = response.data.data.dates;
              let employeeArr=[]
                       for (const date of dates) {
                            for (const data of date.meetings) {
                               
                               if( data.id==id){
                               
                                setMeeting(data)
                               
                                formik.setFieldValue( 'title',data.title, );
                                    formik.setFieldValue( 'date',data.date);
                                    formik.setFieldValue( 'from',data.time_from);
                                    formik.setFieldValue( 'to',data.time_to); 
                                    formik.setFieldValue( 'describe',data.description);
                                    for (const employee of data.employees) {
                                        employeeArr.push(employee.id)

                                    }
                                    setSelectedEmployees(employeeArr)
                            }
                            }
                        }
                
                     
                      
                 
                        setLoading(false)
                
                }).catch(function (error) {
                    if (error.response.status === 401){
                       window.location.pathname="/"
                    }
                  
                })
                
                },[])



                async function onSubmit(event){
                    const values = {
                        api_token: localStorage.getItem("token"),
                        id: id,
                        title: titleRef.current.value,
                        date: dateRef.current.value,
                        time_from: fromRef.current.value,
                        time_to: toRef.current.value,
                        description: describeRef.current.value,
                        ids: JSON.stringify(selectedEmployees),
                    }
                    const today = new Date();
                    const dd = String(today.getDate()).padStart(2, '0');
                    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    const yyyy = today.getFullYear();

                    if(Date.parse(yyyy+'-'+mm+'-'+dd+' '+values.time_from) >= Date.parse(yyyy+'-'+mm+'-'+dd+' '+values.time_to)){
                        setTimeErr(true)
                 

}
else if(selectedEmployees.length==0){
    setEmployeeErr(true)

}
else{
                    editMeeting(values).then((response) => {

                        history("/meetings")

                    })

                }   
                }   

    
    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>
        {  loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:
        <div className="addMeeting " >
       <p>{t("meetings.edit")}</p>
    
      <form className="meetingForm" onSubmit={formik.handleSubmit}>
<div className="meetingFlex">
    <label>{t("meetings.title")}</label>
    <div style={{width:"80%"}}>
    <input value={formik.values.title}  name="title"  ref={titleRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.title && formik.touched.title?"invalid":null}/>
       <p className="errMsg">{formik.errors.title && formik.touched.title?t(`${formik.errors.title}`):null}</p>
       </div>
</div>
<div className="meetingFlex">
    <label>{t("meetings.date")}</label>
    <div style={{width:"80%"}}>
    <input type="date" min={mindate} value={formik.values.date}  name="date"  ref={dateRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.date && formik.touched.date?"invalid":null}/>
       <p className="errMsg">{formik.errors.date && formik.touched.date?t(`${formik.errors.date}`):null}</p>
</div>
</div>
<div className="meetingFlex">
  <div>
<label>{t("requests.tFrom")}</label>
<input type='time' value={formik.values.from}  name="from"  ref={fromRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.from && formik.touched.from?"invalid":null}/>
       <p className="errMsg">{formik.errors.from && formik.touched.from?t(`${formik.errors.from}`):null}</p>

</div>
<div className=' arrow'>
<img src={arrow}/>
</div>
<div >
<label>{t("requests.tTo")}</label>
<input type='time' value={formik.values.to}  name="to"  ref={toRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to || timeErr?"invalid":null}/>
       <p className="errMsg">{formik.errors.to && formik.touched.to?t(`${formik.errors.to}`):null}</p>
{timeErr? <p className="errMsg">{t("meetings.timeErr")}</p>:null}
</div> 
</div>
<textarea placeholder={t("meetings.describe")} value={formik.values.describe}  name="describe"  ref={describeRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.describe && formik.touched.describe?"invalid":null}/>
       <p className="errMsg">{formik.errors.describe && formik.touched.describe?t(`${formik.errors.describe}`):null}</p>

      
        <div className="invite">
            <h1>{t("meetings.invite")}</h1>
<UsrSwiper setSelectedEmployees={setSelectedEmployees} employees={employeesArr} selectedEmployees={selectedEmployees}/>
{employeeErr?<p className="errMsg">{t("meetings.employeeErr")}</p>:null}

        </div>
        <button type="submit">{t("meetings.save")}</button>
        </form>
        </div>}
  

        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}


export default EditMeeting
