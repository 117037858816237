import "../styles/profile.css";

import TopNav from "../components/TopNav";
import SideNavbar from "../components/SideNavbar";
import img from "../images/loginBackground.61eb979b.jpg"
import { RiPencilFill } from "react-icons/ri";
import { BiMenuAltLeft } from "react-icons/bi";
import { BiMenuAltRight} from "react-icons/bi";
import { profile,editPhoto,editAccount } from "../api";
import { useState,useEffect ,useRef} from "react";
import { AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { ScaleLoader } from "react-spinners";
import def from "../images/defaultImg.jpeg"
import { Link } from "react-router-dom";
import * as yup from "yup"
import {useFormik} from "formik";
import { upload } from "@testing-library/user-event/dist/upload";
 
function Profile() {
const [edit,setEdit]=useState(false)
const [hidden,setHidden]=useState(false);
const [salary,setSalary]=useState(false);
const { t ,i18n} = useTranslation();
const[loading,setLoading]=useState(true)
const[userInfo,setUserInfo]=useState()
const[photo,setPhoto]=useState()
const imgRef= useRef();
const [preview,setPreview]=useState();
let emailRegEx =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const Schema= yup.object().shape({
    name: yup.string().required(("validation.name")).min(6,("validation.name_validation")),
    phone: yup.string().required(("validation.phone")),
    email: yup.string().required(("validation.emailRequired")).matches(emailRegEx,("validation.email_validation")),
    date: yup.string().required(("validation.date_required")),
    password: yup.string().min(6,("validation.password_validation")).required(("validation.passwordRequired")),
})

 

    const formik = useFormik({
        initialValues:{
            name:"",
            phone:"",
            email:"",
            date:"",
            password:"",
        },
        validationSchema: Schema,
        onSubmit,
        
        });
    
        const nameRef=useRef();
        const phoneRef=useRef();
        const emailRef=useRef();
        const dateRef=useRef();
            const passwordRef=useRef();


function show() {
console.log("click")
    setHidden(!hidden);
    var x = document.getElementById("profilePass");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }


useEffect(()=>{
  
       const values = {
              api_token: localStorage.getItem("token")
          }
   
       profile(values).then((response) => {
        formik.setFieldValue('name',response.data.name, );
        formik.setFieldValue('phone',response.data.phone);
        formik.setFieldValue('email',response.data.email);
        formik.setFieldValue('date',response.data.birthdate); 

              if(response.data.photo === ""){
                     setUserInfo( response.data)
                     setPhoto( localStorage.getItem("photo"))
                     setLoading(false)
                   
                 }else{
                     setUserInfo( response.data)
                     setPhoto( response.data.photo)
                     setLoading(false)
                     
                 }
             }).catch(function (error) {
                 if (error.response.status === 401){
                     window.location.pathname="/"
                 }
             })
              },[])
 
 //for image formate to be displayable rerenders onChanging image input
 useEffect(() => {

    if (preview) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      
      };
      reader.readAsDataURL(preview);
    } 
    
  }, [preview]);

  function uploadPhoto(){
    
    const formData = new FormData();
    formData.append('photo', preview);
    formData.append('api_token', localStorage.getItem("token"));

    editPhoto(formData).then((response) => {
            localStorage.setItem("photo",photo)
            window.location.reload();
        })
};

  async function onSubmit(){
      const values={
        api_token: localStorage.getItem("token"),
        name: nameRef.current.value,
        email:  emailRef.current.value,
        phone:  phoneRef.current.value,
        birthdate:  dateRef.current.value,
        password: '',
      }
   editAccount(values).then((response) => {
        localStorage.setItem("name",values.name)
         window.location.reload()
    }).catch(function (error) {
        console.log(error)
    })


  }
  function logout(){
  
      localStorage.clear();
      window.location.pathname="/";
  
  }
    return ( 
        <div className="template" >
<div className="container-fluid" style={{height:"100%"}}>
<div className="row" style={{height:"100%"}}>
<div className="col-lg-3 col-md-4" style={{padding:"0"}}>
<SideNavbar/>
</div>

<div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
<TopNav/>


{
loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:

<div className="profileBody">
<div className="profileBtns">
   <Link to="/salary-sheet"><button className="btn1">{i18n.language=="ar"?<BiMenuAltRight size={30}/>:<BiMenuAltLeft size={30}/>}{t("profile.sheet")}</button>
   </Link>   <button onClick={logout} className="btn2">{t("profile.logout")}</button>

</div>
<div className="usrData">
<div className="imgBorder"> 
        <div className="profilePic" style={{backgroundImage: `url(${photo=="null"?def:photo})`}}></div>
  <button onClick={()=>imgRef.current.click() }><RiPencilFill size={25}/></button>
        </div> 
        <button id="upload" onClick={uploadPhoto}>{t("profile.upload")}</button>
        <input type="file" style={{display:"none"}} ref={imgRef} onChange={(e)=> {
        const file= e.target.files[0];
        if(file){
          setPreview(file);
        }
      }
    
    }
    />
</div>
{edit?
<div>
    <form className="editPorfile">
<label>{t("profile.name")}</label>
<input value={formik.values.name}  name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"invalid":null}/>
       <p className="errMsg">{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</p>
      
<label>{t("profile.phone")}</label>
<input value={formik.values.phone}  name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"invalid":null}/>
       <p className="errMsg">{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</p>
      
<label>{t("profile.email")}</label>
<input value={formik.values.email}  name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email?"invalid":null}/>
       <p className="errMsg">{formik.errors.email && formik.touched.email?t(`${formik.errors.email}`):null}</p>
      
<label>{t("profile.birth")}</label>
<input type="date" value={formik.values.date}  name="date"  ref={dateRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.date && formik.touched.date?"invalid":null}/>
       <p className="errMsg">{formik.errors.date && formik.touched.date?t(`${formik.errors.date}`):null}</p>
      
<label>{t("profile.password")}</label>
<div style={{position:"relative"}}>

<input id="profilePass" type="password" value={formik.values.password}  name="password"  ref={passwordRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
       <p className="errMsg">{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</p>
      
{hidden?
<AiOutlineEyeInvisible onClick={show} size={25} className={i18n.language=="ar"?'eye eyeAr':'eye'} />:
<AiOutlineEye onClick={show} size={25} className={i18n.language=="ar"?'eye eyeAr':'eye'} />}</div>
<button id="save" >{t("profile.save")}</button>

</form>
</div>:
<div>
<table >
    <tr>
        <th>{t("profile.company")}</th>
        <td>{userInfo.company}</td>
    </tr>
    <tr>
        <th>{t("profile.hire")}</th>
                <td>{userInfo.join_date}</td>
    </tr>
    <tr>
        <th>{t("profile.birth")}</th>
                <td>{userInfo.birthdate}</td>
    </tr>
    <tr>
        <th>{t("profile.phone")}</th>
                <td>{userInfo.phone}</td>
    </tr>
    <tr>
        <th>{t("profile.email")}</th>
                <td>{userInfo.email}</td>
    </tr>
    <tr>
        <th>{t("profile.salary")}</th>
                <td>{userInfo.salary}</td>
    </tr>
    <tr>
        <th>{t("profile.normal")}</th>
                <td>{userInfo.normalVacations}</td>
    </tr>
    <tr>
        <th>{t("profile.casual")}</th>
                <td>{userInfo.casualVacations}</td>
    </tr>
</table>
<button id="edit" onClick={()=>setEdit(true)}>{t("profile.edit")}</button>
</div>}</div>}


<div className="footer">
<p>{t("login.footer")}</p>
</div>
</div>
</div>
</div>

</div>)
}

export default Profile
