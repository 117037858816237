 
import { useTranslation } from 'react-i18next';
import cryB from '../images/checkout/cryB.svg'
import cryY from '../images/checkout/cryY.svg'
import happyB from '../images/checkout/happyB.svg'
import happyY from '../images/checkout/happyY.svg'
import veryhappyY from '../images/checkout/veryhappyY.svg'
import veryhappyB from '../images/checkout/veryhappyB.svg'
import verySadY from '../images/checkout/verySadY.svg'
import verySadB from '../images/checkout/verySadB.svg'
import { useState,useRef } from 'react';
import {checkIn } from "../api";

function Checkout(props) {
    
    const { t, i18n } = useTranslation();
const[active,setActive]=useState("")
const[errMsg,setErrMsg]=useState(false)
  const reasonRef=useRef()


function checkOutSubmit() {

  let values = {
      api_token: localStorage.getItem("token"),
      longitude: localStorage.getItem("longitude"),
      latitude: localStorage.getItem("latitude"),
      state: active,
      reason: active=="sad"? reasonRef.current.value:""
  };
  
  if(localStorage.getItem("longitude") !== null && localStorage.getItem("latitude") !== null) {
    checkIn(values).then((response) => {
          if (response.data.message === 'success') {
            
              window.location.reload();
          }
      }).catch(function (error) {
        if (error.response.status === 401){
            window.location.pathname="/"
        }
    });
  }else{
    setErrMsg(true)
  }
}



    return (
        <div  >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay notData">

        <div onClick={(e)=>e.stopPropagation()}>
        <div  className="modal-content moodPicker" >
          <p>{t("dashboard.mood")}</p>
  <div className='container-fluid'>
<div className='row'>



<di className="col">
<div className="mood"  onClick={()=>setActive("sad")}  style={{ backgroundImage: `url(${active=="sad"?cryY:cryB})`}}></div>
</di>
<di className="col">
<div className="mood"  onClick={()=>setActive("middle")}  style={{ backgroundImage: `url(${active=="middle"?verySadY:verySadB})`}}></div>
</di>
<di className="col">
<div className="mood"  onClick={()=>setActive("normal")}  style={{ backgroundImage: `url(${active=="normal"?happyY:happyB})`}}></div>
</di>
<di className="col">
<div className="mood" onClick={()=>setActive("happy")} style={{ backgroundImage: `url(${active=="happy"?veryhappyY:veryhappyB})`}}></div>
</di>
</div>
  </div>
{active=="sad"?
  <div className='sadRsn'>
    <p>{t("dashboard.sad")}</p>
    <textarea ref={reasonRef}/>
  </div>:null}
{errMsg?<p className='errMsg'  style={{textAlign:"start",marginTop:"2em"}}>{t("dashboard.locErr")}</p>:null}
  <button onClick={checkOutSubmit} disabled={active==0} style={errMsg?{marginTop:"1em"}:{marginTop:"2em"}} className='birthdayBtn'>{t("requests.send")}</button>
        </div>
         
        </div>
        </div>
       
      </div>
  
    );
  }
  
  export default Checkout;
  
  