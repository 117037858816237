import "../styles/checkin.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import arrow from '../images/toggle.6fa3fa05.svg'
import UsrSwiper from "../components/UsrSwiper";
import { useState,useEffect } from "react";
import { annualVacationsUpdated } from "../api";
import { ScaleLoader } from "react-spinners";

function Vacations() {
    const { t ,i18n} = useTranslation();
    const[loading,setLoading]=useState(true)
    const[annuals,setAnnuals]=useState([])

    useEffect(()=>{
  
        const values = {
               api_token: localStorage.getItem("token")
           }
        
           annualVacationsUpdated(values).then((response) => {
            setAnnuals( response.data.annuals)
          setLoading(false)
              }).catch(function (error) {
                if (error.response.status === 401){
                    window.location.pathname="/"
                }
            })
              
              
              },[])
    
    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>
        {
loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:
        <div  style={{margin:"2em 0"}}>
      <table className="table table-hover table-striped" >
    <tr>
              <th>{t("vacations.name")}</th>
              <th>{t("vacations.from")}</th>
              <th>{t("vacations.to")}</th>

          </tr>
          <tbody>
          {annuals && annuals.reverse().map((data,index)=>

          <tr key={index}>
              <td>{data.name}</td>
              <td>{data.from}</td>
              <td>{data.to}</td>
          </tr>

          )}
          
          </tbody>
      </table>
        </div>}
  

        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}


export default Vacations
