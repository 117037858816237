import "../styles/sideNav.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import img from "../images/loginBackground.61eb979b.jpg"
import { useTranslation } from 'react-i18next';
import def from "../images/defaultImg.jpeg"
import { useState,useEffect } from "react";
import { Navigation } from "swiper";
function UsrSwiper(props) {
  const { t, i18n } = useTranslation();
  const[quan,setQuan]=useState(0)



  function handleAdd(id){

let newArr=props.selectedEmployees
let findId = (props.selectedEmployees).findIndex(x => x === id);

if(findId!== -1){
  newArr.splice(findId, 1);
  props.setSelectedEmployees(newArr)
  setQuan(quan-1)
}
else{

  newArr.push(id)
  props.setSelectedEmployees(newArr)
  setQuan(quan+1)
}
  }
    return ( 
        <>
        <div>
         
        </div>
        <Swiper
        key={i18n.language}
          slidesPerView={5}
          spaceBetween={30}
        navigation={true}
        modules={[Navigation]} 
       
          className={i18n.language=="ar"?"mySwiper  btnAr":"mySwiper  btnEn"}

        >

{ props.employees && props.employees.map((data,index)=>
          <SwiperSlide key={index}>
            <div className={props.selectedEmployees.includes(data.id)?"usrs active":"usrs"} onClick={()=>handleAdd(data.id)}>
              <div className="usrPic">
          <div style={{backgroundImage: `url(${data.image? data.image:def})`}}></div>

            </div>

            <p>{data.name}</p>
            </div>
            
            </SwiperSlide>
)}


 
     
        </Swiper>
      </>)
}

export default UsrSwiper
