import "../styles/sideNav.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next';
import def from "../images/defaultImg.jpeg"
import { useState } from "react";
import { Navigation } from "swiper";
function Employees(props) {
  const { t, i18n } = useTranslation();
 


    return ( 
        <>
        <div>
         
        </div>
        <Swiper
        key={i18n.language}
          slidesPerView={5}
          spaceBetween={30}
        navigation={true}
        modules={[Navigation]} 
       
          className={i18n.language=="ar"?"mySwiper  btnAr":"mySwiper  btnEn"}

        >

{ props.employees && props.employees.map((data,index)=>
          <SwiperSlide key={index} className="homeSwiper">
            <div className="usrs active" >
              <div className="usrPic">
          <div style={{backgroundImage: `url(${data.image? data.image:def})`}}></div>

            </div>

            <p>{data.name}</p>
            </div>
            
            </SwiperSlide>
)}


 
     
        </Swiper>
      </>)
}

export default Employees
