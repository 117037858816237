import "../styles/meetings.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import img from "../images/loginBackground.61eb979b.jpg"
import { AiFillPlusCircle } from "react-icons/ai";
import Accordion from 'react-bootstrap/Accordion'
import { IoIosArrowDown } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import Calendar from 'react-calendar';
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { meetings } from "../api";
import { ScaleLoader } from "react-spinners";
import def from "../images/defaultImg.jpeg"
import { generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function Meetings() {
    const { t ,i18n} = useTranslation();
    const [date, setDate] = useState(new Date());
    const[loading,setLoading]=useState(false)
    const[dataArr,setDataArr] = useState([])
    const[meetingsArr,setMeetingsArr] = useState([])
    const[meetingNum,setMeetingNum] = useState(0)
    const[datesArr,setDatesArr] = useState([])
    const[selectedDate,setSelectedDate] = useState()
    const[placeholder,setPlaceholder] = useState(t("meetings.dateBy"))
let history=useNavigate()

    const handleProceed = (id) => {
        history(generatePath("/edit-meeting/:id", { id }));
        
      }; 



useEffect(()=>{
  
    const values = {
           api_token: localStorage.getItem("token")
       }

    
       meetings(values).then((response) => {

        let datesArr=[]
        let dataArr=[]
        let meetingsArr=[]
        let dates = response.data.data.dates;
        let numbers = 0;
      datesArr.push({ value: "All", label: "All"})
       for (const date of dates) {
             let obj = { value: date.date, label: date.date}
            datesArr.push(obj)
            dataArr.push(date)
            for (const meeting of date.meetings) {
                meetingsArr.push(meeting)
                numbers +=1
            }
        }

        setDatesArr(datesArr)
     
        setDataArr(dataArr)
        setMeetingsArr(meetingsArr)
        setMeetingNum(numbers)
 
        setLoading(false)

}).catch(function (error) {
    if (error.response.status === 401){
       window.location.pathname="/"
    }
  
})

},[])


function handleChange(dateValue) {

    setSelectedDate(dateValue)
    if(dateValue !== "All"){
        let index = dataArr.findIndex(m => m.date === dateValue);
        let newMeetingsArr = [];
        let numbers = 0;
        if(index !== -1){
            let meetingsArr = dataArr[index].meetings;
            for (const meeting of meetingsArr) {
                newMeetingsArr.push(meeting);
                numbers +=1;
            }
            setPlaceholder(t("meetings.dateBy"))
        }
        else{
            setSelectedDate("")
        }
        setMeetingsArr(newMeetingsArr)
        setMeetingNum(numbers)
      
    }else {
        let numbers = 0;
        let newMeetingsArr = [];
        setPlaceholder(t("meetings.dateBy"))
        for (const date of dataArr) {
            for (const meeting of date.meetings) {
                newMeetingsArr.push(meeting)
                numbers +=1
            }
        }
        setMeetingsArr(newMeetingsArr)
        setMeetingNum(numbers)
      
      
    }
};


function onChangeCalender(date){
  
 
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
      let x = yyyy+'-'+mm+'-'+dd;

   handleChange(x);

setPlaceholder(x)

     setDate(date)
};
    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>

      {  loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:
        <div className="meetinngsBody container-fluid" style={{margin:"2em 0"}}>
        

       <div className="row">
<div className="col-md-8 ">
<div className="selectType">
       <select defaultValue={""} value={selectedDate} onChange={(e)=>handleChange(e.target.value)}>
           <option value="" disabled >{placeholder}</option>
           { datesArr && datesArr.map((data,index)=>
                     <option value={data.value} key={index}>{data.label}</option>

          )} 
       </select>
      <Link to="/add-meeting">
       <button >{t("meetings.add")}<AiFillPlusCircle size={30}  style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}/></button>
       </Link>
        </div>
        <p className="meetingNo">{t("meetings.have")} {meetingNum} {t("meetings.meeting")}</p>
        <hr/>

        <Accordion flush alwaysOpen>
        { meetingsArr && meetingsArr.map((data,index)=>
  <Accordion.Item eventKey={index} key={index}>
    <Accordion.Header className={index==0?"first":null}>
        <div>
        <p id="meetingName">{data.title}      <FaRegEdit onClick={()=>handleProceed(data.id)} id="editMeeting"/></p>
<p id="meetingTime">{data.date} | {data.time_from} - {data.time_to}</p></div>
<div>
    <IoIosArrowDown size={25} className="open"/>
 

</div>
        </Accordion.Header>
    <Accordion.Body>
        <div className="meetingNote">
            <p>{data.description}</p>
        </div>
        <div className="meetingMembers" style={i18n.language=="ar"?{direction:"ltr"}:{direction:"rtl"}}>
           {data.employees.length>0?
         data.employees.map((data,index)=>
             <div key={index} style={{display:"inline-block"}}>
             <div className="imgBorder" style={i18n.language=="ar"? {right:`${index*50}px`}:{left:`${index*50}px`}}>
             <div className="profilePic" style={{backgroundImage: `url(${data.image?data.image:def})`}}></div>
       </div>
             </div>  )
           :null}
       
      
        </div>
        
        </Accordion.Body></Accordion.Item>)}

     
        </Accordion>
</div>
<div className="col-md-4 ">
    <div className="calenderBody">
<Calendar   
 onChange={onChangeCalender}                        
 value={date} />
<hr/>
<p className="history">{t("meetings.history")}</p>

{ meetingsArr && meetingsArr.map((data,index)=>
<div className="hDiv" key={index}>
<p className="hDate">{data.date}</p>
<p className="hName">{data.title}</p>

</div>
)}
</div>
</div>
       </div>
        </div>}
        
        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}


export default Meetings
