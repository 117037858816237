import "../styles/login.css";
import img from "../images/loginBackground.61eb979b.jpg"
import logo from "../images/logo.2663735b.svg"
import { Link } from "react-router-dom";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router";
import { ScaleLoader } from "react-spinners";
import { useState,useRef } from "react";
import { forgetPassword } from "../api";
import { useTranslation } from 'react-i18next';

function ForgetPassword() {
       const [errorMsgModel, setErrorMsgModel]=useState(false);
       const [msgAr, setMsgAr]=useState();
   const [msgEn, setMsgEn]=useState();
   const [loading, setLoading]=useState(false);
   const [success, setSuccess]=useState(false);
   const { t } = useTranslation();

   let history=useNavigate()

       let emailRegEx =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
   
       const Schema= yup.object().shape({
         email: yup.string().required(("validation.emailRequired")).matches(emailRegEx,("validation.email_validation")),
         
         })
   
    
   const formik = useFormik({
     initialValues:{
       email:"",
     },
     validationSchema: Schema,
     onSubmit,
     
     });
     
     const emailRef=useRef();
   


       
     async function onSubmit(event){
       setLoading(true)
       setSuccess(false)
       setErrorMsgModel(false)
       const values={
    
        email: emailRef.current.value,

       }

 
          forgetPassword(values).then((response) => {
 
          setLoading(false)
          setSuccess(true)
       
          }).catch(function(error){
    
             setErrorMsgModel(true)
             setLoading(false)
       
         })
     }


    return ( 
        <div className="login" style={{backgroundImage: `url(${img})`}}>

<div className="loginForm">
       <div className="logo" style={{backgroundImage: `url(${logo})`}}></div>
       <p id="hello">Hello</p>
       <p id="signIn">Forget Password ?</p>
       {loading?
       <div className="loader"   style={{height:"3em",justifyContent:"start"}}>
    <ScaleLoader height={25} width={2} color="white"/></div>:null}
{success?<p className="success">Success process, please check your email</p>:null}
       <form  onSubmit={formik.handleSubmit}>
       <input  value={formik.values.email}  name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email || errorMsgModel?"invalid":null}/>
       <p className="errMsg">{formik.errors.email && formik.touched.email?t(`${formik.errors.email}`):null}</p>

       <p  className="errorMsgModel">{errorMsgModel? "Invalid email or password":null}</p>

      <button type="submit">Reset</button>
      </form>

      <Link to="/">
<p className="forgetLogin">Login</p></Link>
       </div>
   
</div>)
}

export default ForgetPassword
