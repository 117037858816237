 
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import arrow from '../images/toggle.6fa3fa05.svg'
import { useState,useRef } from 'react';
import { makeRequest } from '../api';

function RequestType(props) {
   
    const { t, i18n } = useTranslation();
    const[typeId,setTypeId]=useState("")
    const[index,setIndex]=useState(0)
    const[errDate,setErrDate]=useState(false)
    const[errTime,setErrTime]=useState(false)

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const mindate = yyyy + '-' + mm + '-' + dd;

const dateFromRef= useRef()
const dateToRef= useRef()
const timeFromRef= useRef()
const timeToRef= useRef()
const msgRef= useRef()


function handleChange(e){
   const value= JSON.parse(e)
    setTypeId(value.id)
    setIndex(value.index)
}


function handleSubmit(e){
    e.preventDefault();
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    let data = {
        api_token: localStorage.getItem("token"),
        typeId: typeId,
        dateFrom: props.types[index].dateFrom? dateFromRef.current.value:"",
        dateTo: props.types[index].dateTo?  dateToRef.current.value:"",
        timeFrom: props.types[index].timeFrom? timeFromRef.current.value:"",
        timeTo: props.types[index].timeTo? timeToRef.current.value:"",
        msg: msgRef.current.value,
    };
    if(data.dateTo !=='' && (Date.parse(data.dateFrom ) > Date.parse(data.dateTo ))) {
       setErrDate(true)
    }else if(data.timeTo !=='' && (Date.parse(yyyy+'-'+mm+'-'+dd+' '+data.timeFrom) >= Date.parse(yyyy+'-'+mm+'-'+dd+' '+data.timeTo))){
        setErrTime(true)
    }else{
makeRequest(data).then((response) => {
    if(response.data.message === "success"){
        window.location.reload();
    }
}).catch(function (error) {
    console.log(error)
})
    }



}
    return (
        <div  >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay notData"></div>
        <div  className="modal-content notContent request" >
 <form onSubmit={(e)=>handleSubmit(e)}>
     <div className='container-fluid'>
         <div className='row'>
<div className='col-12'>
<label>{t("requests.type")}</label>

   <select defaultValue={""} onChange={(e)=>{
  handleChange(e.target.value)
       }}>
           <option disabled value="">{t("requests.choose")}</option>
       {props.types && props.types.map((data,index)=> 
           <option key={index} value={JSON.stringify({id:data.id,index:index})}>{i18n.language=="ar"?data.NameAr:data.nameEn}</option>
       )}
       </select>
</div>
{typeId==""?null:
<>
{console.log()}
{props.types[index].dateFrom?
<div className='col-6'>
<label>{t("requests.from")}</label>
<input ref={dateFromRef} type='date' min={props.types[index].allowPreviousDate?null:mindate} max={props.types[index].allowUpcomingDate?null:mindate} required />
</div>
:<div className='col-6'></div>}

{props.types[index].dateTo?
<div className='col-6'>
<label>{t("requests.to")}</label>
<input ref={dateToRef}  type='date' min={props.types[index].allowPreviousDate?null:mindate} max={props.types[index].allowUpcomingDate?null:mindate} required className={errDate?"invalid":null}/>
{errDate?<p className="errMsg">Date to must be greater than date from</p>:null}
</div>:<div className='col-6'></div>}

{props.types[index].timeFrom?
<div className='col-5'>
<label>{t("requests.tFrom")}</label>
<input ref={timeFromRef}  type='time' required/>
</div>:<div className='col-5' ></div>}

{props.types[index].timeFrom && props.types[index].timeTo?
<div className='col-2 arrow'>
<img src={arrow}/>
</div>:null}
{props.types[index].timeTo?
<div className='col-5'>
<label>{t("requests.tTo")}</label>
<input ref={timeToRef}  type='time' required className={errTime?"invalid":null}/>
{errTime?<p className="errMsg">Time to must be greater than time from</p>:null}

</div>:<div className='col-5'></div>}


<div className='col-12'>
<label>{t("requests.details")}</label>
<textarea ref={msgRef}  required/>
</div>
</>}
         </div>
     </div>
 
   <button type="submit">{t("requests.send")}</button>

   </form>
        </div>
      </div>
  
    );
  }
  
  export default RequestType;
  
  