
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import def from "../images/defaultImg.jpeg"

import balloon from "../images/balloon.png"

function Birthday(props) {
    
    const { t, i18n } = useTranslation();

  
    return (
        <div  >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay notData">

        <div onClick={(e)=>e.stopPropagation()}>
        <div  className="modal-content notContent birthdayModal">
            { props.birthdays.map((data,index)=>
            <>
           <div key={index} className='birthdayImg'>
        <div className="imgBorder" style={{margin:"auto",marginTop:"1em"}}>
        <div className="profilePic" style={{ backgroundImage: `url(${data.image==""?def: data.image})`}}></div>

        </div>
        <img src={balloon}/>
        </div> 
   <p className='notText'>{data.name}</p>
   <button onClick={()=>{props.setBirthdayEmp(
       {id:data.id,
    name:data.name})
    props.setModal(false)
    props.setBirthdayMsg(true)
    }} className='birthdayBtn'>{t("dashboard.say")}</button>
<hr/>
   </>)}

        </div></div>
        </div>
       
      </div>
  
    );
  } 
  
  export default Birthday;
  
  