import "../styles/tasks.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";

import Task from "../components/Task";
import done from "../images/done.svg"
import log from "../images/backlog.svg"
import progress from "../images/inProgress.svg"
import { tasks } from "../api";
import { AiFillPlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion'

import Calendar from 'react-calendar';
import { useState,useEffect } from "react";



function MyTasks() {
    const { t ,i18n} = useTranslation();

    const [value, onChange] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    const [tasksArr, setTasksArr] = useState([]);
    const [backlogArr, setBacklogArr] = useState([]);
    const [inProgressArr, setInProgressArr] = useState([]);
    const [doneArr, setDoneArr] = useState([]);
    const [backlogNum, setBacklogNum] = useState(0);
    const [inProgressNum, setInProgressNum] = useState(0);
    const [doneNum, setDoneNum] = useState(0);
    const [tasksNum, setTasksNum] = useState(0);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [active, setActive] = useState("all");
const[date,setDate]= useState(new Date());
     


useEffect(()=>{
  
const values={
    api_token: localStorage.getItem("token")
}

tasks(values).then((response) => {

    let dataArr=[]
    let tasksArr=[]
    let backlogArr=[]
    let inProgressArr=[]
    let doneArr=[]

    let tasksNum= 0
    let backlogNum= 0
    let inProgressNum= 0
    let doneNum= 0
    let dates = response.data.data.dates;
    for (const date of dates) {
        dataArr.push(date)
        for (const task of date.tasks) {
            tasksArr.push(task)
            tasksNum +=1
            if (task.status === "backlog") {
                backlogArr.push(task);
                backlogNum += 1
            } else if (task.status === "in-porgress") {
                inProgressArr.push(task);
                inProgressNum += 1
            } else {
                doneArr.push(task);
                doneNum += 1
            }
        }
    }

    setLoading(false)
    setDataArr(dataArr)
    setTasksArr(tasksArr)
    setBacklogArr(backlogArr)
    setInProgressArr(inProgressArr)
    setDoneArr(doneArr)
    setBacklogNum(backlogNum)
    setInProgressNum(inProgressNum)
    setDoneNum(doneNum)
    setTasksNum(tasksNum)
    setProgressPercentage((tasksNum > 0) ? ((doneNum/tasksNum)*100).toFixed(1) : 0)
     }) },[])




   function  onChangeCalender(date){
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = date.getFullYear();
        let x = yyyy+'-'+mm+'-'+dd;
        let obj = { value: x, label: x}
        handleChange(obj);
     
        setDate(date)
    };

    function  handleChange (selectedOption){

        let tasksArr=[]
        let backlogArr=[]
        let inProgressArr=[]
        let doneArr=[]
    
        let tasksNum= 0
        let backlogNum= 0
        let inProgressNum= 0
        let doneNum= 0

        let index = dataArr.findIndex(m => m.date === selectedOption.value);
        if(index !== -1){
            let newTasksArr = dataArr[index].tasks;
            for (const task of newTasksArr) {
                tasksArr.push(task)
                tasksNum +=1
                if (task.status === "backlog") {
                    backlogArr.push(task);
                    backlogNum += 1
                } else if (task.status === "in-porgress") {
                    inProgressArr.push(task);
                    inProgressNum += 1
                } else {
                    doneArr.push(task);
                    doneNum += 1
                }
            }
       
            setTasksArr(tasksArr)
            setBacklogArr(backlogArr)
            setInProgressArr(inProgressArr)
            setDoneArr(doneArr)
            setBacklogNum(backlogNum)
            setInProgressNum(inProgressNum)
            setDoneNum(doneNum)
            setTasksNum(tasksNum)
            setProgressPercentage((tasksNum > 0) ? ((doneNum/tasksNum)*100).toFixed(1) : 0)
          
        
                
        }else{
            setTasksArr([])
            setBacklogArr([])
            setInProgressArr([])
            setDoneArr([])
            setBacklogNum(0)
            setInProgressNum(0)
            setDoneNum(0)
            setTasksNum(0)
            setProgressPercentage(0)
          
            
        }}
 

    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>
        
        <div className="meetinngsBody container-fluid" style={{margin:"2em 0"}}>
       <div className="row">
<div className="col-8">
<div className="taskStatus">
       <button onClick={()=>setActive("all")} className={active=="all"?"activeState":null}>{t("tasks.all")} </button>
       <button onClick={()=>setActive("log")}  className={active=="log"?"activeState":null}>{t("tasks.log")}</button>
       <button onClick={()=>setActive("inprogress")}  className={active=="inprogress"?"activeState":null}>{t("tasks.inprogress")}</button>
       <button onClick={()=>setActive("done")}  className={active=="done"?"activeState":null}>{t("tasks.done")}</button>
        </div>
     

        <Accordion flush alwaysOpen  className={i18n.language=="ar"?"taskCollapse taskCollapseAr":"taskCollapse" }>

        

     {
active=="all"?
        tasksArr.length>0?
         tasksArr.map((data,index)=>
        <>
        <Task data={data} index={index} arrLength={tasksArr.length}/>
     </>)
     :<p className="noData">{t("profile.noTasks")}</p>

     :
     active=="log"?
        backlogArr.length>0?
        backlogArr.map((data,index)=>
        <>
        <Task data={data} index={index} arrLength={backlogArr.length}/>
     </>)
     :<p className="noData">{t("profile.noTasks")}</p>
     
     :
     active=="inprogress"?
     inProgressArr.length>0?
     inProgressArr.map((data,index)=>
     <>
     <Task data={data} index={index} arrLength={inProgressArr.length}/>
  </>)
  :<p className="noData">{t("profile.noTasks")}</p>:
  active=="done"?
  doneArr.length>0?
  doneArr.map((data,index)=>
  <>
  <Task data={data} index={index} arrLength={doneArr.length}/>
</>):<p className="noData">{t("profile.noTasks")}</p>:null
    
    }
        
        </Accordion>
</div>
<div className="col-4">
<Link to="/add-task">
       <button className="addTask">{t("tasks.add")}<AiFillPlusCircle  size={30}  style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}/></button>
       </Link>
    <div className="calenderBody">
<Calendar   onChange={onChangeCalender} value={date}/>
<hr/>
<div className="progBar">
<p>{t("tasks.progress")}</p>
<div className="progress">
  <div className="progress-bar" role="progressbar" aria-label="Example with label" style={{width: `${progressPercentage}%`}} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100">{progressPercentage}%</div>
</div>
</div>
<hr/>
<p className="history" style={{marginBottom:"1em"}}>History</p>
<div className="taskHistory">
    <div>
<img src={log}/>
<p className="historyNum" id="log" >{backlogNum}</p>
<p>{t("tasks.pLog")}</p>

   </div>
   <div>
   <img src={progress}/>
   <p className="historyNum" id="inProgress">{inProgressNum}</p>
<p>{t("tasks.pInprogress")}</p>

   </div>
   <div>
   <img src={done}/>
   <p className="historyNum" id="done">{doneNum}</p>
<p>{t("tasks.pDone")}</p>

   </div>

</div>

</div>
</div>
       </div>
        </div>
        
        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}


export default MyTasks
