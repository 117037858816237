import "../styles/help.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import { useState,useRef } from "react";
import { MdOutlinePostAdd } from "react-icons/md";
import { FaExclamation } from "react-icons/fa";
import { help } from "../api";
import * as yup from "yup"
import {useFormik} from "formik";
function Help() {

    const { t ,i18n} = useTranslation();
    const [type,setType] = useState("");
    const [typeErr,setTypeErr] = useState(false);
    const [success,setSuccess] = useState(false);
 
    const Schema= yup.object().shape({
        msg: yup.string().required(("validation.msg_required"))
      
        })

  const formik = useFormik({
    initialValues:{
        msg:"",

    },
    validationSchema: Schema,
    onSubmit,
    
    });
    
    const msgRef=useRef();
  
  
    async function onSubmit(){
        setSuccess(false)
        setTypeErr(false)
        const values = {
            api_token: localStorage.getItem("token"),
            msg: msgRef.current.value,
            type:type,
        }
        if(type==""){
            setTypeErr(true)
        }
        else{
        help(values).then((response) => {
setSuccess(true)
            setTypeErr(false)
        }).catch(function (error) {
                 if (error.response.status === 401){
                     window.location.pathname="/"
                 }
             })
            }
    }
    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>
        
        <div className="help">
       <p id="about">{t("help.about")}</p>
       <div >
          {typeErr? <p className="errMsg" style={{textAlign:"center"}}>{t("help.typeErr")}</p>:null}
          {success? <p className="success" style={{textAlign:"center"}}>{t("help.success")}</p>:null}

          
<div className={type=="feature"?"helpType activeType":"helpType"}>
    <div onClick={()=>setType("feature")}>
        <MdOutlinePostAdd size={60}/>
    </div>
    <p >{t("help.new")}</p>
</div>
<span></span>
<div className={type=="complaint"?"helpType activeType":"helpType"}>
<div onClick={()=>setType("complaint")}>
        <FaExclamation  size={60}/>
    </div>
    <p>{t("help.complain")}</p>
</div>
 
       </div>
       <form onSubmit={formik.handleSubmit}>
       <textarea placeholder={t("help.message")}  value={formik.values.msg}  name="msg"  ref={msgRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.msg && formik.touched.msg?"invalid":null}/>
       <p className="errMsg">{formik.errors.msg && formik.touched.msg?t(`${formik.errors.msg}`):null}</p>
 
       <button type="submit">{t("help.send")}</button>
       </form>
       <div className="contactUs">
<p>{t("help.chat")}</p>
<a href="https://wa.me/201152907995" target="_blank"><button style={{width:"100%"}}>{t("help.contact")}</button></a>
       </div>
        </div>
        
        <div className="footer">
        <p>{t("login.footer")}</p>

        </div>
        </div>
        </div>
        </div>
        
        </div>)
}


export default Help
