
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Login from './screens/Login';
import ForgetPassword from './screens/ForgetPassword';
import Home from './screens/Home';
import Profile from './screens/Profile';
import CheckIn from './screens/CheckIn';
import Requests from './screens/Requests';
import Template from './screens/Template';
import Meetings from './screens/Meetings';
import AddMeeting from './screens/AddMeeting';
import EditMeeting from './screens/EditMeeting';
import MyTasks from './screens/MyTasks';
import Vacations from './screens/Vacations';
import Help from './screens/Help';
import Chat from './screens/Chat';
import { useTranslation } from 'react-i18next';
import SalarySheet from './screens/SalarySheet';
import EditTask from './screens/EditTask';
import { useEffect } from 'react';
import AddTask from './screens/AddTask';
import { askForPermissioToReceiveNotifications } from './firebase/firebaseConfig';
function App() {
const{i18n}=useTranslation()
  if(i18n.language=="ar") {
    require('./styles/fontsAr.css');
}
else{
  require('./styles/fontsEn.css');
}


useEffect(()=>{

  askForPermissioToReceiveNotifications();

},[])

  return (
    <div className="App">
       <BrowserRouter>

            <Routes> 
            <Route exact path={"/"} element={<Login/>}/>
            <Route exact path={"/forget-password"} element={<ForgetPassword/>}/>
            <Route exact path={"/dashboard"} element={<Home/>}/>
            <Route exact path={"/salary-sheet"} element={<SalarySheet/>}/>

            <Route exact path={"/check-in-history"} element={<CheckIn/>}/>
            <Route exact path={"/profile"} element={<Profile/>}/>
            <Route exact path={"/my-requests"} element={<Requests/>}/>    
                    <Route exact path={"/temp"} element={<Template/>}/>
                    <Route exact path={"/chat"} element={<Chat/>}/>

                    <Route exact path={"/meetings"} element={<Meetings/>}/>
                    <Route exact path={"/add-meeting"} element={<AddMeeting/>}/>
                    <Route exact path={"/add-task"} element={<AddTask/>}/>

                    <Route exact path={"/my-tasks"} element={<MyTasks/>}/>
                    <Route exact path={"/public-vacations"} element={<Vacations/>}/>
                    <Route exact path={"/help"} element={<Help/>}/>
                    <Route exact path={"/edit-task/:id"} element={<EditTask/>}/>

                    <Route exact path={"/edit-meeting/:id"} element={<EditMeeting/>}/>



</Routes></BrowserRouter>
    </div>
  );
}

export default App;
