
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion'
import def from "../images/defaultImg.jpeg"
import done from "../images/done.svg"
import log from "../images/backlog.svg"
import progress from "../images/inProgress.svg"
import { IoIosArrowDown } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { changeStatus } from '../api';
import { generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function Task(props) {
   
    const { t, i18n } = useTranslation();
    let history=useNavigate()

const handleProceed = (id) => {
    history(generatePath("/edit-task/:id", { id }));
    
  }; 
  
  function  handleStatus(id, status){
        let values = {
            api_token: localStorage.getItem("token"),
            id: id,
            status: status,

        };
        changeStatus(values).then((response) => {
            window.location.reload()
        }).catch(function (error) {
            console.log(error)
        })
    }
    return (
        <Accordion.Item eventKey={props.index} key={props.index} style={props.index!=props.arrLength-1?{borderColor:`${props.data.color}`,borderBottom:"none"}:{borderColor:`${props.data.color}`}} className={props.index==0 && i18n.language=="ar"?"firstAr borderAr": i18n.language=="ar"?"borderAr": props.index==0? "firstTask":null }>

    <Accordion.Header className={props.index==0 ?"first":null}>
        <div>
        <p id="meetingName"> {props.data.project_name} <FaRegEdit onClick={()=>handleProceed(props.data.id)}/></p>
<p id="meetingTime">{props.data.title}</p>
<p className="taskDate">{props.data.deadline}</p></div>

<div>
    <IoIosArrowDown size={25} className="open"/>
 

</div>
        </Accordion.Header>
    <Accordion.Body className="tasksBody">
        <div className="flexTasks">
        <div className="meetingNote">
        {props.data.description}
        </div> 
        <div className="meetingMembers" style={i18n.language=="ar"?{direction:"ltr"}:{direction:"rtl"}}>
           {props.data.employees.length>0?
         props.data.employees.map((data,index)=>
             <div key={index} style={{display:"inline-block"}}>
             <div className="imgBorder" style={i18n.language=="ar"? {right:`${index*50}px`}:{left:`${index*50}px`}}>
             <div className="profilePic" style={{backgroundImage: `url(${data.image?data.image:def})`}}></div>
       </div>
             </div>  )
           :null}
       
      
        </div></div>
        <hr/>
        <div className="flexTasks changeState">
<p>{t("tasks.change")}</p>
<div>
   
<button  onClick={() => handleStatus(props.data.id, "backlog")} disabled={props.data.status=="backlog"?true:false} className={props.data.status=="backlog"?"activeStatus":null} id="log"><img src={log}/></button>
<span></span>
<button  onClick={() => handleStatus(props.data.id, "in-porgress")}  disabled={props.data.status=="in-porgress"?true:false}  className={props.data.status=="in-porgress"?"activeStatus":null} id="inProgress"><img src={progress}/></button>
<span></span>
<button  onClick={() => handleStatus(props.data.id, "done")}  disabled={props.data.status=="done"?true:false}  className={props.data.status=="done"?"activeStatus":null} id="done"><img src={done}/></button>

</div>
        </div>
        </Accordion.Body></Accordion.Item>
  
    );
  }
  
  export default Task;
  
  