
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';

function ErrorModal(props) {
    
    const { t, i18n } = useTranslation();

  
    return (
        <div  >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay notData">

        <div>
        <div  className="modal-content notContent" >
   <p className='notTitle'>{t(`${props.errMsg}`)}</p>

        </div></div>
        </div>
       
      </div>
  
    );
  }
  
  export default ErrorModal;
  
  