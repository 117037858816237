import "../styles/chat.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import { useState,useEffect, useRef } from "react";
import img from "../images/loginBackground.61eb979b.jpg"
import { IoSend } from "react-icons/io5";

import { ImLink } from "react-icons/im";


function Chat() {
    const { t ,i18n} = useTranslation();
const fileRef=useRef()
    
    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>
        
        <div className="chat">
       <div className="chatDiv">
<div className="messg" style={i18n.language=="ar"?{marginRight:"auto"}:{marginLeft:"auto"}}>
<p id="txtDate">2020-10-01 23:53:03</p>
<p id="txtAcc">acc name</p>
<p id="txt">message</p>
</div>
<div className="messg" style={i18n.language=="ar"?{marginRight:"auto"}:{marginLeft:"auto"}}>
<p id="txtDate">2020-10-01 23:53:03</p>
<p id="txtAcc">acc name</p>
<p id="txt">message</p>
</div>
<div className="messg" style={i18n.language=="ar"?{marginRight:"auto"}:{marginLeft:"auto"}}>
<p id="txtDate">2020-10-01 23:53:03</p>
<p id="txtAcc">acc name</p>
<img  src={img}/>

</div>

       </div>
  <div className="send">
      <input type="text" placeholder={t("help.send")}/>
      <input type="file" ref={fileRef} style={{display:"none"}}/>
      <button className="sendBtn" style={i18n.language=="ar"?{left:"20px"}:{right:"20px"}}><IoSend className={i18n.language=="ar"?"flip":null}/>
      </button>

      <button onClick={()=>
    fileRef.current.click()} className="attach" style={i18n.language=="ar"?{left:"70px"}:{right:"70px"}}><ImLink size={50}/>
      </button>
 
  </div>
        </div>
        
        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}


export default Chat
