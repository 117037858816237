import "../styles/checkin.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import { checkInHistory,salarySheets } from "../api";
import{useEffect,useState}from 'react'
import { ScaleLoader } from "react-spinners";
function CheckIn() {
const[loading,setLoading]=useState(true)
    const { t ,i18n} = useTranslation();
    const[periods,setPeriods]=useState()
   const[progress,setProgress]=useState()
   const[hoursMax,setHoursMax]=useState()
   const[dayHoursMax,setDayHoursMax]=useState()
   const[hoursEmployee,setHoursEmployee]=useState()


useEffect(()=>{
  
  const values = {
         api_token: localStorage.getItem("token")
     }
  
  checkInHistory(values).then((response) => {
    let progress = 0
    if(response.data.hoursMax !== 0){
        progress = (response.data.hoursEmployee / parseInt(response.data.hoursMax)) * 100;
    }
    setHoursMax(parseInt(response.data.hoursMax))
    setDayHoursMax(response.data.dayhoursMax)
    setHoursEmployee(response.data.hoursEmployee)

    setPeriods(response.data.periods)
    setProgress(progress)
    setLoading(false)
        }).catch(function (error) {
          if (error.response.status === 401){
              window.location.pathname="/"
          }
      })
        
        
        },[])
    return ( 
        <div className="template" >
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>
        {
loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:

        <div className="checkIn">
     
            <div id="current">
        <p >{t("checkin.current")}</p>
        <div className="progressBar">
        <div className="progress">
  <div className="progress-bar" role="progressbar" aria-label="Example with label" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{`${parseInt(progress)}%`}</div>
</div></div></div>
<hr/>


{periods && periods.reverse().map((data,index)=>

 
<div className="checkinHistory">
  <p className="checkinDate">{data.period}</p>  

  <div className="progressBar">
        <div className="progress">
  <div className="progress-bar progress-bar-striped " role="progressbar" aria-label="Example with label" style={{width: `${parseInt((data.hours/dayHoursMax)*100)}`}} aria-valuenow={parseInt((data.hours/dayHoursMax)*100)} aria-valuemin="0" aria-valuemax="100">{parseInt((data.hours/dayHoursMax)*100)}%</div>
</div>
{data.sessions && data.sessions.map((data,index)=>
<div className="checkinTime">
    <div>
        <p>{t("checkin.from")} <span>{data.from}</span> </p>
        <p style={i18n.language=="ar"?{marginRight:"2em"}:{marginLeft:"2em"}}>
            
        {t("checkin.to")} <span>{data.to}</span></p>
        </div>
        <p>{data.total}</p>

</div>)}

  </div>
</div>
      
     )
}
<hr/>
        </div>}
        
        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}

export default CheckIn
