import AxiosProvider from "./AxiosProvider";

    export const login = (values) => {
      return AxiosProvider.post('login',values,{
          headers: {
              'Content-Type': 'application/json',
             
              'Accept': 'application/json'
          }})
    }  

    export const forgetPassword = (values) => {
        return AxiosProvider.post('resetPassword',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  
  
      export const homePage = (values) => {
        return AxiosProvider.post('homePage',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  
  
      export const checkIn = (values) => {
        return AxiosProvider.post('checkIn',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  


      export const profile = (values) => {
        return AxiosProvider.post('profile',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 
      export const editAccount = (values) => {
        return AxiosProvider.post('editAccount',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 
      export const editPhoto = (values) => {
        return AxiosProvider.post('editPhoto',values,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
      }  
   

      export const salarySheets = (values) => {
        return AxiosProvider.post('salary-sheets',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  
      export const checkInHistory = (values) => {
        return AxiosProvider.post('checkInHistory',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  

      export const annualVacationsUpdated = (values) => {
        return AxiosProvider.post('annualVacationsUpdated',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  
      
      export const getNotificationsUpdated = (values) => {
        return AxiosProvider.post('getNotificationsUpdated',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  

       
      export const requests = (values) => {
        return AxiosProvider.post('request-types',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  
      export const makeRequest = (values) => {
        return AxiosProvider.post('make-request',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 
      
      export const requestsByType = (values) => {
        return AxiosProvider.post('get-request-by-type',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  

      export const meetings = (values) => {
        return AxiosProvider.post('get-meetings',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  

      export const addMeeting = (values) => {
        return AxiosProvider.post('add-meeting',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  
      export const editMeeting = (values) => {
        return AxiosProvider.post('edit-meeting',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  

      export const employees = (values) => {
        return AxiosProvider.post('company-users',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }  

      export const help = (values) => {
        return AxiosProvider.post('suggest',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }
      export const checkBirthday = (values) => {
        return AxiosProvider.post('checkBirthday',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }
      export const birthdayMsg = (values) => {
        return AxiosProvider.post('send-birthday-msg',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      }

      export const tasks = (values) => {
        return AxiosProvider.post('get-tasks',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 

      export const addTask = (values) => {
        return AxiosProvider.post('add-task',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 

      export const updateToken = (values) => {
        return AxiosProvider.post('update-push-token',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 

      export const changeStatus = (values) => {
        return AxiosProvider.post('change-task-status',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 
      export const editTask = (values) => {
        return AxiosProvider.post('edit-task',values,{
            headers: {
                'Content-Type': 'application/json',
               
                'Accept': 'application/json'
            }})
      } 