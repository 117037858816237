import "../styles/profile.css";

import TopNav from "../components/TopNav";
import SideNavbar from "../components/SideNavbar";
import img from "../images/loginBackground.61eb979b.jpg"
import { RiPencilFill } from "react-icons/ri";
import { BiMenuAltLeft } from "react-icons/bi";
import { BiMenuAltRight} from "react-icons/bi";
import { salarySheets } from "../api";
import { useState,useEffect } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { ScaleLoader } from "react-spinners";
import def from "../images/defaultImg.jpeg"
function SalarySheet() {

const { t ,i18n} = useTranslation();
const[loading,setLoading]=useState(true)
const[sheets,setSheets]=useState()


useEffect(()=>{
  
       const values = {
              api_token: localStorage.getItem("token")
          }
   
          salarySheets(values).then((response) => {
         
            setSheets(response.data.sheets)
                     setLoading(false)
                   
                
             }).catch(function (error) {
                 if (error.response.status === 401){
                     window.location.pathname="/"
                 }
             })
              },[])



    return ( 
        <div className="template" >
<div className="container-fluid" style={{height:"100%"}}>
<div className="row" style={{height:"100%"}}>
<div className="col-lg-3 col-md-4" style={{padding:"0"}}>
<SideNavbar/>
</div>

<div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
<TopNav/>
{
loading?
<div className="loader" style={{height:"80vh"}}>
<ScaleLoader color="var(--themeColor)"/></div>:

<div className="salarySheet">
<div className="container-fluid ">
  <div className="row gy-4">
      {
      sheets.lenghth>0?
      sheets.map((sheet,index) => {

<div class="col-sm-6 ">
<p className="slryDate p-3">{sheet.month},{sheet.year}</p>
<div class=" salaryBreakdown ">
<div>
<p>{t("profile.full")}</p>
<p>{parseFloat(sheet.fullSalary).toFixed(2)}</p>
</div>
<hr/>
<div>
<p>{t("profile.work")}</p>
<p>{sheet.workDays}</p>
</div>
<hr/>
<div>
<p>{t("profile.vacation")}</p>
<p>{sheet.vacations}</p>
</div>
<hr/>
<div>
<p>{t("profile.absent")}</p>
<p>{sheet.absent}</p>
</div>
<hr/>
<div>
<p>{t("profile.deduction")}</p>
<p>{parseFloat(sheet.deduction).toFixed(2)}</p>
</div>
<hr/>
<div>
<p>{t("profile.advance")}</p>
<p>{parseFloat(sheet.advance).toFixed(2)}</p>
</div>
<hr style={{marginBottom:"0"}}/>
<div id="net">
<p>{t("profile.net")}</p>
<p>{parseFloat(sheet.netSalary).toFixed(2)}</p>
</div>
 </div>
</div>

      })
    :<p className="noData">{t("profile.noData")}</p>
    }
   
  
    
</div></div></div>}


<div className="footer">
<p>{t("login.footer")}</p>
</div>
</div>
</div>
</div>
</div>

)
}

export default SalarySheet
