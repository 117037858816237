import "../styles/login.css";
import img from "../images/loginBackground.61eb979b.jpg"
import logo from "../images/logo.2663735b.svg"
import { AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";
import { useState,useRef, lazy } from "react";
import { Link } from "react-router-dom";
import { login } from "../api";
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router";
import { ScaleLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';

function Login() { 
  const { t } = useTranslation();

    const [hidden,setHidden]=useState(true);
    const [errorMsgModel, setErrorMsgModel]=useState(false);
    const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [loading, setLoading]=useState(false);
let history=useNavigate()
    let emailRegEx =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const Schema= yup.object().shape({
      email: yup.string().required(("validation.emailRequired")).matches(emailRegEx,("validation.email_validation")),
      password: yup.string().min(6,("validation.password_validation")).required(("validation.passwordRequired")),
      
      })

 
const formik = useFormik({
  initialValues:{
    email:"",
    password:"",
  },
  validationSchema: Schema,
  onSubmit,
  
  });
  
  const emailRef=useRef();
      const passwordRef=useRef();

       
    async function onSubmit(event){
      setLoading(true)
      const values={
   
       email: emailRef.current.value,
       password: passwordRef.current.value,
   app_version: '2.0.0',
     platform: 'web'
      }
        console.log(values)

         login(values).then((response) => {

          localStorage.setItem("logo", response.data.companyLogo);

            localStorage.setItem("token", response.data.api_token);
            localStorage.setItem("color", response.data.companyColorHEX);
            document.documentElement.style.setProperty('--themeColor', response.data.companyColorHEX);

            localStorage.setItem("name", response.data.name);
            localStorage.setItem("position", response.data.position);
            if(response.data.photo === ""){
                localStorage.setItem("photo", null);
            }else {
                localStorage.setItem("photo", response.data.photo);
            }
            history("/dashboard")
         }).catch(function(error){
   
            setErrorMsgModel(true)
            setLoading(false)
      
        })
    }

    function show() {
console.log("click")
        setHidden(!hidden);
        var x = document.getElementById("pass");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

    return ( 
        <div className="login" style={{backgroundImage: `url(${img})`}}>

<div className="loginForm">
       <div className="logo" style={{backgroundImage: `url(${logo})`}}></div>
       <p id="hello">Hello</p>
       <p id="signIn">Sign In to your account</p>
       {loading?
       <div className="loader" style={{height:"15em"}}>
    <ScaleLoader color="white"/></div>:
       <form  onSubmit={formik.handleSubmit}>
       <input  value={formik.values.email}  name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email || errorMsgModel?"invalid":null}/>
       <p className="errMsg">{formik.errors.email && formik.touched.email?t(`${formik.errors.email}`):null}</p>

       <div style={{position:"relative"}}>
       <input id="pass" type="password"  value={formik.values.password}  name="password"  ref={passwordRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password || errorMsgModel?"invalid":null}/>
       <p className="errMsg">{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</p>

       {!hidden?
<AiOutlineEyeInvisible onClick={show} size={25} className='eye'/>:
<AiOutlineEye onClick={show} size={25} className='eye' />}

       </div>
       <Link to="/forget-password"> <p className="forget">Forget Password?</p>
       </Link>

       <p  className="errorMsgModel">{errorMsgModel? "Invalid email or password":null}</p>
       <button type="submit" >Login</button>
       </form>   }
         </div>  
   
</div>)
}

export default Login
