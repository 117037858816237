import "../styles/sideNav.css";

import company from "../images/company.png"
import nav from "../images/topNav.png"
import { GoBell } from "react-icons/go";
import Notifications from "./Notifications";
import { useState } from "react";
import NotificationText from "./NotificationText";
import { useEffect } from "react";
import { getNotificationsUpdated } from "../api";
function TopNav() {
const[notification,setNotification]=useState(false)
const[notificationText,setNotificationText]=useState(false)
const[notificationData,setNotificationData]=useState([])
const[notificationLength,setNotificationLength]=useState(0)
const[notifText,setNotifText]=useState()



useEffect(()=>{
  
    const values = {
           api_token: localStorage.getItem("token")
       }

       getNotificationsUpdated(values).then((response) => {
        
setNotificationData(response.data.notifications)
let num = 0;
for (let notification of response.data.notifications) {
    if (notification.state === "") {
        num += 1
    }
setNotificationLength(num)
   
}

    }).catch(function (error) {
        if (error.response.status === 401) {
            window.location.pathname = "/"
        }})

},[])
    return ( 
     <>
    {notification? <Notifications setText={setNotifText} data={notificationData} setShowData={setNotificationText} setModal={setNotification}/>:null}
    {notificationText? <NotificationText data={notifText} setModal={setNotificationText}/>:null}

<div className="topNav" style={{backgroundImage: `url(${nav})`}}>
<div className="companyImg" style={{backgroundImage: `url(${ localStorage.getItem("logo")? localStorage.getItem("logo"):""})`}}></div>

<button onClick={notificationLength>0?()=>setNotification(true):null}  data-count={notificationLength==0?"":notificationLength} className={notificationLength==0?"count empty":"count"}>
    <GoBell size={30} />
</button>
</div></>)
}

export default TopNav
