import "../styles/requests.css";
import { useTranslation } from 'react-i18next';
import SideNavbar from "../components/SideNavbar";
import TopNav from "../components/TopNav";
import { AiFillPlusCircle } from "react-icons/ai";
import RequestType from "../components/RequestType";
import { useState,useEffect } from "react";
import { requests,requestsByType } from "../api";
import { ScaleLoader } from "react-spinners";

function Requests() {
    const { t ,i18n} = useTranslation();
const[reqType,setReqType]=useState(false)
const[loading,setLoading]=useState(true)
const[types,setTypes]=useState([])
const[typeId,setTypeId]=useState()
const[request,setRequests]=useState([])

useEffect(()=>{
  
    const values = {
           api_token: localStorage.getItem("token")
       }

    
requests(values).then((response) => {

    setTypes(response.data.types)

    const values2 = {
        api_token: localStorage.getItem("token"),
typeId:typeId?typeId:response.data.types[0].id
    }
    requestsByType(values2).then((response) => {

        setRequests( response.data.requests)
    setLoading(false)
           })

       })
    
    
    
    },[typeId])
    return ( 

        <div className="template" >
            {reqType?
            <RequestType types={types} setModal={setReqType}/>:null
        }
        <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"100%"}}>
        <div className="col-lg-3 col-md-4" style={{padding:"0"}}>
        <SideNavbar/>
        </div>
        
        <div className="col-lg-9 col-md-8" style={{padding:"0",position:"relative"}}>
        <TopNav/>



        <div className="requests">
        <div className="selectType">
       <select value={typeId} onChange={(e)=>{
           setLoading(true);
           setTypeId(e.target.value)
       }}>
       {types && types.map((data,index)=> 
           <option key={index} value={data.id}>{i18n.language=="ar"?data.NameAr:data.nameEn}</option>
       )}
       </select>
       <button onClick={()=>setReqType(true)}>{t("requests.add")} <AiFillPlusCircle size={30}  style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}/></button>
        </div>

{ 
loading?
       <div className="loader" style={{height:"80vh"}}>
    <ScaleLoader color="var(--themeColor)"/></div>:
        <div className="container-fluid " style={{margin:"2em 0"}}>
  <div className="row gx-5">
  {
  request.length!==0?
  <>{
  request && request.map((data,index)=> 
   
    <div className="col-lg-6 " key={index}>
<div className="requestData">
    <div>
<p className="reqHead">{t("requests.state")}</p>
<p className={i18n.language=="ar"?"borderRight reqData":"reqData"} >{data.state}</p></div>
<hr/>
<div className="reqDate">
<p className="reqHead">{t("requests.date")}</p>
<div className={i18n.language=="ar"?"borderRight":null}>
<p className="reqStart">{data.dateFrom}</p>
<p id="to">{t("requests.to")}</p>
<p className="reqEnd">{data.dateTo}</p>
</div></div>
<hr/>
<div>
<p className="reqHead">{t("requests.message")}</p>
<p className={i18n.language=="ar"?"borderRight reqData":"reqData"} >{data.msg}</p></div>
</div>
    </div>)
  }  </>

    :<p className="noData">{t("profile.noRequests")}</p>
}
    


    </div>
    </div>}
        </div>
        <div className="footer">
        <p>{t("login.footer")}</p>
        </div>
        </div>
        </div>
        </div>
        
        </div>)
}

export default Requests
