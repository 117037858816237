
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { birthdayMsg } from "../api";
import { useRef,useState} from 'react';
import img from "../images/birthdayTemplate.svg"


function BirthdayMessage(props) {
    
    const { t, i18n } = useTranslation();
    const [success,setSuccess]=useState(false)

const msgRef=useRef()
  
function sendMsg(){
    const values={
        api_token: localStorage.getItem("token"),
        msg: msgRef.current.value,
        id: props.data.id,
    }

    birthdayMsg(values).then((response) => {
        setSuccess(true)
    }).catch(function (error) {
        console.log(error)
    })

}

    return (
        <div  >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay notData">

        
        <div  className="birthdayMsg" onClick={(e)=>e.stopPropagation()}>
            
        <div className='bdImg' style={{ backgroundImage: `url(${img})`}}>
<p>{t("dashboard.bd1")} {props.data.name} {t("dashboard.bd2")}</p>
        </div>

        <div>
          {success?  <p className='success'>{t("help.success")}</p>:null}
            <textarea ref={msgRef}/>
            <button onClick={sendMsg} className='birthdayBtn'>{t("requests.send")}</button>

        </div>
        </div>
       
        
        </div>
    
       
      </div>
  
    );
  } 
  
  export default BirthdayMessage;
  
  