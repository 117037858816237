import "../styles/sideNav.css";
import img from "../images/loginBackground.61eb979b.jpg"
import def from "../images/defaultImg.jpeg"

import { NavLink ,Link} from "react-router-dom";
import {useEffect, useState} from 'react';
import { BsFillChatDotsFill} from "react-icons/bs";
import { useTranslation } from 'react-i18next';



function SideNavbar() {
    const { t,i18n } = useTranslation();
    const [lang,setLang]=useState("en");

    const changeLanguage = (e) => {
          var language = e.target.value;
           i18n.changeLanguage(language);
           localStorage.setItem("LANG",JSON.stringify(language ));
           setLang(language);
      
         if(language==="en"){
         document.getElementById("root").style.direction="ltr";
       
         }
         else{
           document.getElementById("root").style.direction="rtl";
         }
  
         
         };
    
        // presist langunage and document direction and website name
      
         useEffect(() => {
          document.documentElement.style.setProperty('--themeColor', localStorage.getItem('color'));

  const data=  JSON.parse(localStorage.getItem("LANG"));
  i18n.changeLanguage(data);
  setLang(data);
  
  
  if(i18n.language==="en"){
    document.getElementById("root").style.direction="ltr";
  
    }
    else if(i18n.language==="ar"){
      document.getElementById("root").style.direction="rtl";
    
    }
    else{
     document.getElementById("root").style.direction="ltr";
    }
  
    if(i18n.language==="ar"){
     document.title="  مطاعم الرومانسية"
  }else {
     document.title="Al Romansiah restaurants "
  
  }
       },[lang,window.location.pathname]); 
   
  
  
    return ( 
   
        <div className="sideNav" >
     
            <div className="profileInfo">
            <div className="imgBorder">
        <div className="profilePic" style={{ backgroundImage: `url(${localStorage.getItem("photo")=="null"?def: localStorage.getItem("photo")})`}}></div>

        </div>
              
        <p className="usrName"> { localStorage.getItem("name")}</p>
        <p className="usrDescribe">{ localStorage.getItem("position")}</p>
        </div>
        <p className="navText">{t("nav.hi")} { localStorage.getItem("name")}</p>
        <p className="welcome">{t("nav.welcome")}</p>
      <Link to="/chat"> <button className="chat">
        <BsFillChatDotsFill size={35} className={i18n.language=="ar"?"flip":null}/></button></Link> 
        <div className="links">
            <NavLink to="/dashboard"><p>{t("nav.home")}</p>
</NavLink>
<NavLink to="/profile">
<p>{t("nav.profile")}</p></NavLink>

<NavLink to="/check-in-history">
<p>{t("nav.checkIn")}</p>
</NavLink>
<NavLink to="/my-requests">
<p>{t("nav.requests")}</p>

</NavLink>
<NavLink to="/meetings"> <p>{t("nav.meetings")}</p></NavLink>
<NavLink to="/my-tasks">
<p>{t("nav.tasks")}</p></NavLink>
<NavLink to="/public-vacations"> 
<p>{t("nav.vacation")}</p></NavLink>
<NavLink to="/help">
<p>{t("nav.help")}</p></NavLink>

<div>
<select value={lang} onChange={(e)=>changeLanguage(e)}>
<option value={"en"}> English </option>
<option value={"ar"}> العربية </option>
    </select>
    
    </div>

        </div>
</div>)
}

export default SideNavbar
